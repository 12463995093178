<template>
<!-- eslint-disable -->
    <!-- print invoices  -->
    <div class="root" justify='center' v-show="false">
          <v-card  v-if="sheets" id="sheets-doc">
            <v-container fluid v-for="(sheet_id, i) in sheets.qrs" :key="i">
              <div v-if="i > 0" class="html2pdf__page-break"></div>
              <v-row>
                <v-spacer></v-spacer>
                <p class="text-h2"
                >
                  Medication Prescription</p>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <div >
                  <v-container class="sheet_info">
                    <v-row>
                      <p class="p-margin">Doctor: {{capitalize_(sheets.doctor_name)}}</p>
                    </v-row>
                    <v-row>
                      <p class="p-margin">Prescription: {{capitalize_(sheets.prescription_name)}}</p>
                    </v-row>
                  </v-container>
                </div>
                <v-spacer></v-spacer>
                <div >
                  <v-container>
                    <v-row>
                      <img 
                      class="qr" 
                      :src="sheet_id"></img >
                    </v-row>
                  </v-container>
                </div>
              </v-row>
              <v-row>
                <v-divider class="divider-margins"></v-divider>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-container fluid>
                    <template v-for="(product, index) in sheets.products">

                      <v-row :class="'row-border '+((index == 8 || (index-8)%10==0)? 'new-page-product-margin':'')" align="center">
                        <v-col cols="2">
                          <img 
                          class="product-img" 
                          contain 
                          :src="product.img_src || require('@/assets/med_icon.png')"
                          ></img>
                        </v-col>

                        <v-col cols="10" style="padding-top:15pt;">
                            <p class="product-title">
                              {{capitalize_(product.name)}}
                              <small>{{capitalize_(product.manufacturer)}}</small>
                            </p>
                            <p class="product-description">{{firstCapitalize_(product.description.length > 140   ? product.description.slice(0,140  )+'...': product.description)}}</p>
                        </v-col>
                      </v-row>
                      <div v-if="(index == 7 || (index-7)%10==0) && (index != sheets.products.length-1)" class="html2pdf__page-break"></div>
                    </template>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
    </div>
</template>

<script>
/* eslint-disable */
import Api from '@/api/api'
import Html2Pdf from 'js-html2pdf';
import qrcode  from 'qrcode';
import {capitalize, firstCapitalize} from '@/util/helpers'
export default {
  name:"PrescriptionSheets",
  data:()=>({
    sheets:null
  }),
  props:{
    input_sheets:{
      type:Object,
      // example:s {
      //   prescription_name:'Heart medications',
      //   doctor_name:'Moyaser the legend',
      //   prescription_id:1,
      //   doctor_id:2,
      //   sheet_ids:[1,2,3,4,5,6],
      //   products:[
      //     {
      //       name: "Asperine",
      //       manufacturer: "Afarmacorp",
      //       description: "a hurt medication, an asperine.",
      //       img_src:
      //         "http://localhost/server/files/product_image_file_21_2022_02_25T05-01-32-938Z.jpeg",
      //     },
      //     {
      //       name: "Asperine",
      //       manufacturer: "Afarmacorp",
      //       description: "a hurt medication, an asperine.",
      //       img_src:
      //         "http://localhost/server/files/product_image_file_21_2022_02_25T05-01-32-938Z.jpeg",
      //     },
      //   ]
      // }
    },
  },
  created(){
    this.$on('print',(cb)=>{
      this.generate(cb)
    })
  },
  methods:{
    async get_src(val){
      return (await qrcode.toDataURL(val))
    },
    capitalize_(string){
      return capitalize(string)
    },
    firstCapitalize_(string){
      return firstCapitalize(string)
    },
    async generate(ev){
      if(!ev.input_sheets){
        return 
      }
      await this.$set(this,'sheets',JSON.parse(JSON.stringify(ev.input_sheets)))
      await this.$set(this.sheets, 'qrs',[])
      for(const sheet_id of this.sheets.sheet_ids){
        await this.sheets.qrs.push(await qrcode.toDataURL(`${Api.baseURL}/prescription_sheet_form/${sheet_id}-${this.sheets.prescription_id}-${this.sheets.doctor_id}`))
      }
       
      
      this.$nextTick().then(()=>{
        this.$nextTick().then(()=>{
          const element = document.getElementById('sheets-doc')
          const exporter  =  new Html2Pdf(element, {
            margin:       0,
            filename:     `${this.sheets.doctor_name}_${this.sheets.prescription_name}_${this.sheets.sheet_ids.lengh}.pdf`,
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { scale: 2, logging: true, dpi: 192, letterRendering: true },
            jsPDF:        { unit: 'mm', format: 'a4', orientation: 'portrait' }
          });
          exporter.getPdf(true).then(pdf=>{
            ev.cb(pdf)
          })

        })
      })
    }
  }
};
</script>

<style scoped>
.text-h2 {
  font-size: 34pt;
  margin-bottom: 30pt;
  margin-top: 30pt;
}
.p-margin {
  margin-right: 30pt;
  margin-left: 30pt;
}
.divider-margins {
  margin-right: 15pt;
  margin-top: 15pt;
  margin-left: 15pt;
  margin-bottom: 0pt;
}
.row-border {
  padding: 5pt;
  align-content: center;
  height: 60pt;
  margin-left: 15pt;
  margin-right: 15pt;
  margin-top: 10pt;
  border: 2px solid rgba(133, 133, 133, 0.4);
  border-radius: 10pt;
}
.product-title {
  margin:0pt;
  font-size: 11pt;
}
.product-description{
  margin: 0pt;
  font-size: 10pt;
}
.root {
  width: 450pt;
}
.qr{
  padding: 0pt;
  margin: 0pt;
  margin-right: 30pt;
  margin-left: 30pt;
  max-height: 60pt;
}
.sheet_info{
  margin-top:15pt;
}
.product-img {
  padding:0pt;
  margin:0pt;
  margin-top:5pt;
  max-height: 43pt;
  object-fit: contain;
  max-width: 43pt;
}
.new-page-product-margin{
  margin-top:50pt;
}
</style>