<template>
  <!-- eslint-disable -->
  <v-card :height="height" >
    <v-card-title class="text-h4 pb-0 grey--text">
      <v-row align="stretch" justify="center">
        <span class="pa-0">
          {{title}}
        </span>
      </v-row>
    </v-card-title>
    <v-card-text  class="fill-height">
      <v-container class="fill-height">
        <v-row v-if="items.length " class="" align="stretch" justify="center">
          <v-col v-for="(item, index) in items" :key="index" cols="12" md="6">
            <v-card class="blue darken-1" dark height="100%">
              <v-card-title>
                {{item.x}}
              </v-card-title>
              <v-card-text>
                {{item.y}}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else justify="center" align="center">
          <div>No Data Available</div>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
import Api from '@/api/api'
export default {
  name: "ListingCard",
  data:()=>({
    items:[
    ]
  }),
  props: {
    url: {
      type: String
    },
    title: {
      type: String
    },
    height: {
      type: [String, Number],
      default: () => {
        return "100%";
      }
    }
  },
  created(){
    Api.post(this.url).then(res=>{
      if(!res.data.result.data.length){
        return
      }
      this.items = res.data.result.data
    })
  }
};
</script>

<style></style>
