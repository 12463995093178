<template>
  <!-- eslint-disable -->
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <div class="pt-4 pb-2 px-4 text-h3 grey--text">
            Filters
          </div>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-container>
              <v-row>
                <v-btn @click="clear_all_filters" text color="royal"
                  >Clear All</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  @click="$emit('apply', { filters, dialog })"
                  text
                  color="royal"
                  >Apply</v-btn
                >
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row justify="center" class="pa-0 ma-0">
          <v-col cols="12" md="6" v-for="(filter, index) in filters" :key="index" class="pa-0 ma-0">
            <v-card class="pa-0 ma-2" outlined elevation="0">
              <v-card-text class="pa-0 ma-0">
                <component
                  class="pa-0 ma-0"
                  :ref="`el_${filter.component.name}`"
                  v-bind:is="filter.component.name"
                  v-bind="filter"
                ></component>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  name: "TableOuterFilter",
  data: () => ({
    dialog: {
      show: false
    }
  }),
  props: {
    filter_icon: {
      type: String,
      default: "mdi-filter-variant"
    },
    filters: {
      type: Object,
      default: () => ({})
      // example: {
      //   datetime: {
      //     component: {
      //       tab: {
      //         title: "Date And Time",
      //         icon: "mdi-calendar-clock"
      //       },
      //       name: "date-time-filter"
      //     },
      //     values: {}
      //   },
      //   userfilter: {
      //     component: {
      //       tab: {
      //         title: "By User",
      //         icon: "mdi-account"
      //       },
      //       name: "user-filter"
      //     },
      //     values: {}
      //   }
      // }
    }
  },
  computed: {},
  methods: {
    clear_all_filters() {
      for (const filter_key in this.filters) {
        this.$refs[`el_${this.filters[filter_key].component.name}`]?.[0]?.$emit(
          "clear"
        );
      }
      this.$emit('apply', { filters:this.filters, dialog:this.dialog })
    }
  }
};
</script>
