<template>
  <!-- eslint-disable -->
  <v-container fluid>

    <!-- Item table -->
    <filtered-data-table
      :cleard="0"
      :search_fields="[]"
      :headers="headers"
      api_url="/dashboard/fetch/doctor_left_sheets"
      :fetch_all="true"
      title="Doctors Left Sheets"
      subtitle="Doctors left sheets"
      :no_search="true"
      :no_filter="true"
      filter_icon="mdi-doctor"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
export default {
  name:"DoctorLeftSheetsTable",
  data: () => ({
    // clear Doctro Dialog Data
    item_clear_dialog: {
      show: false,
      item: null,
      refresh: null,
      btns: {
        loading: false
      }
    },

    // table
    headers: [
      { text: "Doctor Name", value: "name" },
      { text: "Speciality", value: "speciality" },
      { text: "Left Count", value: "left_count", sortable:false },
    ],
    table_actions: {
    },
  }),
};
</script>

<style></style>
