<template>
  <!-- eslint-disable -->
  <!-- values.filter.field_values Table Dialog -->
  <v-card flat class="pa-0 ma-0">
    <v-card-text>
      <v-row class="pa-0 ma-0" justify="center" >
        <v-col class="pa-0 ma-0" align-self="stretch" cols="12" :md="options.hide_chips?12:3">
          <v-container fluid>
            <v-row class="pr-6">
              <v-spacer></v-spacer>
              <v-btn
                small
                icon
                @click="values.filter.field_values.splice(0)"
                color="grey"
                dark
              >
                Clear All
              </v-btn>
            </v-row>
            <v-row v-if="!options.hide_chips">
              <v-chip
                class="ma-1"
                close
                @click:close="
                  values.filter.field_values.splice(
                    values.filter.field_values.indexOf(value),
                    1
                  )
                "
                v-for="value in values.filter.field_values"
                :key="value"
                >{{ value.length > 20 ? value.slice(0,20)+'...':value }}</v-chip
              >
            </v-row>
          </v-container>
        </v-col>
        <v-col class="pa-0 ma-0" cols="12" :md="options.hide_chips?12:9">
          <filtered-data-table
            :deleted="0"
            :search_fields="values.table.search_fields"
            :table_actions="table_actions"
            :headers="values.table.headers"
            :api_url="values.table.api_url"
            :title="values.table.title"
            :subtitle="values.table.subtitle"
            :no_search="values.table.no_search"
            :no_filter="values.table.no_filter"
            :filters="values.table.filters"
            :filter_icon="values.table.filter_icon"
            @add_field="add_field($event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-container> </v-container>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  name: "FieldFilter",
  data: () => ({
    items: []
  }),
  props: {
    options: {
      type: Object,
      default:()=>({}),
      // example:{
      //   hide_chips:false,
      // }
    },
    values: {
      type: Object,
      default: () => ({})
      // example:{
      //   table:{
      //     no_search:false,
      //     no_filter:true,
      //     filters:{},
      //     filter_icon:'',
      //     api_url:'/user/fetch',
      //     headers: [
      //       { text: "ID", value: "user_id" },
      //       { text: "Name", value: "name" },
      //       { text: "User Name", value: "user_name" },
      //     ],
      //     title:"Filter By User",
      //     subtitle: "Filter results by User",
      //     search_fields:[
      //       'name', 'user_name',
      //     ]
      //   },
      //   filter:{
      //     field:'user_name',
      //     table:'user',
      //     target_local_key: 'created_by_user', 
      //     target_foreign_key: 'user_id', 
      //     field_values:[]
      //   }
      // }
    }
  },
  created() {
    this.$on("clear", () => {
      console.log("clearing");
      this.values.filter.field_values.splice(0);
    });
  },
  computed: {
    table_actions() {
      return {
        name: "Actions",
        actions: [
          {
            event: "add_field",
            icon: item => {
              if (
                this.values.filter.field_values.includes(
                  item[this.values.filter.field]
                )
              ) {
                return "mdi-minus";
              } else {
                return "mdi-plus";
              }
            }
          }
        ]
      };
    }
  },
  methods: {
    add_field(event) {
      console.log(event);
      if (!this.isActive(event.item[this.values.filter.field])) {
        this.$set(
          this.values.filter.field_values,
          this.values.filter.field_values.length,
          event.item[this.values.filter.field]
        );
      } else {
        this.values.filter.field_values.splice(
          this.values.filter.field_values.indexOf(
            event.item[this.values.filter.field]
          ),
          1
        );
      }
    },
    isActive(field_value) {
      if (!this.values.filter) {
        this.$set(this.values, "filter", {});
      }
      if (!this.values.filter.field_values) {
        this.$set(this.values.filter, "field_values", []);
      }
      return this.values.filter.field_values?.indexOf(field_value) != -1;
    }
  }
};
</script>

<style></style>
