<template>
  <!-- eslint-disable -->
  <field-filter
  :values="field_filter_values"
  :options="options"
  ></field-filter>
</template>

<script>
/* eslint-disable */
export default {
  name: "ManufacturerFilter",
  data(){
    return {
        table:{
          no_search:false,
          no_filter:true,
          filters:{},
          filter_icon:'',
          api_url:'/manufacturer/fetch',
          headers: [
            { text: "ID", value: "manufacturer_id" },
            { text: "Name", value: "name" },
          ],
          title:"Filter By Manufacturer", 
          subtitle: "Filter results by Manufacturer",
          search_fields:[
            'name',
          ]
        },
        filter:{
          field:'name',
          table:'manufacturers',
          target_local_key: 'manufacturer_id', 
          target_foreign_key: 'manufacturer_id', 
          field_values:[]
        }
    }
  },
  props: {
    options:{
      type:Object,
      default:()=>({})
    },
    values: {
      type:Object,
      default: ()=>({
      })
    }
  },
  created(){
    if(this.values.target_local_key){
      console.log(this.values)
      this.filter.target_local_key = this.values.target_local_key
    }
    this.$on('clear',()=>{
      this.values.filter.field_values.splice(0)
    })
  },
  computed: {
    field_filter_values(){
      if(!this.values.table){
        this.$set(this.values,'table', this.table)
      }
      if(!this.values.filter){
        this.$set(this.values,'filter', this.filter)
      }
      return this.values
    }
  },
};
</script>

<style></style>
