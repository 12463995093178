<template>
  <!-- eslint-disable -->
  <v-container>
    <v-row justify="center" class="pa-0 ma-0">
      <v-col
        cols="12"
        md="6"
        v-for="(filter, index) in filtered_tables.filters"
        :key="index"
        class="pa-0 ma-0"
      >
        <v-card class="pa-0 ma-2" outlined elevation="0">
          <v-card-text class="pa-0 ma-0">
            <chips-filtered-data-table
              :filter_api_url="filter.filter_api_url"
              :data_api_url="filtered_tables.data_api_url"
              :headers="filtered_tables.headers"
              :title="filter.title"
              :subtitle="filter.subtitle"
              :event="filter.event"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
export default {
  name: "ChipsFilteredDataTables",
  props: {
    filtered_tables: {
      type: Object,
      default: () => ({}),
    //   example: {
    //     data_api_url: "",
    //     headers: [{ value: "name", text: "Name" }],
    //     filters: [
    //       {
    //         filter_api_url: "",
    //         title: "",
    //         subtitle: "",
    //         event: {
    //           filters: {
    //             accomulative_field1: { // filter backend handler name
    //               values: {
    //                 filter: {
    //                   field: "name",
    //                   table: "manufacturers",
    //                   target_local_key: "collective_manufacturers",
    //                   target_foreign_key: "manufacturer_id",
    //                   field_values: []
    //                 }
    //               }
    //             }
    //           }
    //         }
    //       }
    //     ]
    //   }
    }
  }
};
</script>

<style></style>
