<template>
  <!-- eslint-disable -->
  <v-dialog
    v-model="dialog.show"
    :fullscreen="$vuetify.breakpoint.mobile"
    :width="$vuetify.breakpoint.mdAndUp ? '1200px' : ''"
  >
    <!-- activator  -->
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab color="royal" dark elevation="3" v-on="on" v-bind="attrs">
        <v-icon>{{ filter_icon }}</v-icon>
      </v-btn>
    </template>

    <!-- Tabs -->
    <v-card :height="$vuetify.breakpoint.mobile ? '' : '670px'">
      <v-card-text class="py-0 px-3">
        <v-row>
          <v-col class="pa-0 ma-0" cols="12">
            <v-tabs
              color="royal"
              :vertical="$vuetify.breakpoint.mdAndUp"
              show-arrows
              icons-and-text
            >
              <template v-for="(filter, index) in filters">
                <v-tab :key="index">
                  <v-icon class="my-1">
                    {{ filter.component.tab.icon }}
                  </v-icon>
                  {{ filter.component.tab.title }}
                </v-tab>
                <v-tab-item :key="index">
                  <component
                    :ref="`el_${filter.component.name}`"
                    v-bind:is="filter.component.name"
                    v-bind="filter"
                  ></component>
                </v-tab-item>
              </template>
            </v-tabs>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0 px-4">
            <v-container>
              <v-row>
                <v-btn @click="clear_all_filters" text color="royal"
                  >Clear All</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="dialog.show = false" text color="royal"
                  >Cancel</v-btn
                >
                <v-btn
                  @click="$emit('apply', { filters, dialog })"
                  text
                  color="royal"
                  >Apply</v-btn
                >
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
export default {
  name: "TableFilter",
  data: () => ({
    dialog: {
      show: false
    },
  }),
  props: {
    filter_icon: {
      type: String,
      default: "mdi-filter-variant"
    },
    filters: {
      type: Object,
      default: () => ({})
      // example: {
      //   datetime: {
      //     component: {
      //       tab: {
      //         title: "Date And Time",
      //         icon: "mdi-calendar-clock"
      //       },
      //       name: "date-time-filter"
      //     },
      //     values: {}
      //   },
      //   userfilter: {
      //     component: {
      //       tab: {
      //         title: "By User",
      //         icon: "mdi-account"
      //       },
      //       name: "user-filter"
      //     },
      //     values: {}
      //   }
      // }
    }
  },
  computed: {},
  methods: {
    clear_all_filters() {
      for (const filter_key in this.filters) {
        this.$refs[`el_${this.filters[filter_key].component.name}`]?.[0]?.$emit(
          "clear"
        );
      }
    }
  }
};
</script>

<style>
.footer {
  width: 100%;
  /* background:red; */
  bottom: 0px;
  position: absolute;
}
</style>
