/* eslint-disable */
import axios from "axios";
import store from "../../store/index";


// let baseURL = 'http://192.168.1.105:3000'
// if(window.navigator.userAgent.indexOf('Linux') !== -1){
//   baseURL = location.origin
// }
// let api_main_url = location?.hostname? `http://${location?.hostname}:3000` : 'https://ag-tmapp.aramtech.ly';
let api_main_url = 'https://ag-tmapp.aramtech.ly'
// let api_main_url = 'http://127.0.0.1:3000'
console.log("base url", api_main_url);

import { Capacitor } from "@capacitor/core";
const platform = Capacitor.getPlatform()
const web = platform == 'web'


const Api = axios.create({
    timeout: 60000,
    baseURL: `${api_main_url}/server/api/tmapp`,
});

Api.interceptors.request.use(function (config) {
    config.headers.app = "tmapp";
    // console.log(config.baseURL+config.url)

    if (store.getters["user/auth"]) {
        config.headers.Authorization = store.getters["user/token"];
    }
    return config;
});

Api.interceptors.response.use(
    function (response) {
        //   console.log('api response', JSON.stringify(response, null, 4))
        return response;
    },
    async function (error) {
        const response = error.response;
        if (response?.status == 421) {
            store.dispatch("user/logout", null, { root: true });
        }

        const err = error.response;
        // const response = error.response
        if (response?.status == 423) {
            await store.dispatch("user/logout", null, { root: true });
        }
        error.message =
            error.response?.data?.error?.msg ||
            error.response?.data?.error?.message ||
            error.response?.data?.error?.name ||
            error.response?.data?.msg ||
            error.response?.data?.message ||
            error.response?.data?.name ||
            error.msg ||
            error.message;
        console.log(error.name, error.message, error.code, err, error);
        if (!error?.config?.quiet) {
            if (!error?.response || error?.response?.status === 502) {
                error.networkError = true;
                error.network_error = true;
                store.commit("user/set_notification", "Network Error, Please try again later.");
            }
        }

        throw error;
    }
);
Api.baseURL = api_main_url;

Api.__post = Api.post
Api.post = async function (url, body, options = { sinceMins: 0 }) {
    // await window.sleep(1000)
    async function refetch() {
        console.log('refreshing', url)

        /**
         * @type {import('axios').AxiosResponse<any, any>}
         */
        const response = await Api.__post(url, body, options)
        if(options?.sinceMins > 0){
            console.log(response.data)
            try {
                localStorage.setItem(key, JSON.stringify({
                    timestamp: Date.now(),
                    response: {
                        data: response.data, 
                    },
                }))
            } catch (error) {
                localStorage.clear()
                await store.dispatch('user/update_localstorage', null, {root: true})
            
                localStorage.setItem(key, JSON.stringify({
                    timestamp: Date.now(),
                    response: {
                        data: response.data, 
                    },
                }))
            }
        }
        return response
    }
    const match_body = {
        url: url,
        body: body,
    }
    const key = JSON.stringify(match_body)
    if (options?.sinceMins > 0 && !options?.now && !web) {
        const local_cache = localStorage.getItem(key)
        if (local_cache) {
            const cached_response = JSON.parse(local_cache) || {}
            if (!!cached_response.timestamp && ((Date.now() - parseInt(cached_response.timestamp)) / (60e3)) < options.sinceMins) {
                return cached_response.response
            } else {
                return await refetch()
            }
        } else {
            return await refetch()
        }
    } else {
        return await refetch()
    }
}


export default Api;
