<template>
    <!-- eslint-disabled -->
    <div v-if="loading" class="loading_cover">
        <v-progress-circular :size="70" :width="7" class="laoding_cover_progresser" color="purple" indeterminate />
    </div>
</template>

<script>
/* eslint-disabled */
import { sync } from "vuex-pathify";
export default {
    name: "LoadingCover",
    data() {
        return {
            loading: false,
        };
    },
    watch: {
        loading_cover: {
            deep: true,
            handler(val) {
                this.loading = Object.values(val).filter((el) => !!el).length;
            },
        },
    },
    created() {
        for (const key in this.loading_cover) {
            this.$set(this.loading_cover, key, false);
        }
    },
    computed: {
        ...sync("user", {
            loading_cover: "visualization@loading_cover",
        }),
    },
};
</script>

<style>
.loading_cover {
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: aliceblue;
}
.laoding_cover_progresser {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
