const padStart = (string, targetLength, padString) => {
    targetLength = targetLength >> 0;
    string = String(string);
    padString = String(padString);

    if (string.length > targetLength) {
        return String(string);
    }

    targetLength = targetLength - string.length;

    if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length);
    }

    return padString.slice(0, targetLength) + String(string);
};

const padDate = (n, length = 2) => padStart(n, length, "0");
export { padDate };
export { padStart };
/**
 *
 * @param {Date} date
 * @param {Boolean} getdate
 * @param {Boolean} gettime
 * @returns {String}
 */
export function dash_date_formater(date, getdate = true, gettime = true) {
    date = new Date(date);
    const Month = padDate(String(date.getMonth() + 1));
    const DayOfMonth = padDate(String(date.getDate()));
    const FullYear = date.getFullYear();

    const Hour = padDate(String(date.getHours()));
    const Minutes = padDate(String(date.getMinutes()));
    const Seconds = padDate(String(date.getSeconds()));
    if (getdate && gettime) {
        return `${FullYear}-${Month}-${DayOfMonth} ${Hour}:${Minutes}:${Seconds}`;
    } else if (getdate && !gettime) {
        return `${FullYear}-${Month}-${DayOfMonth}`;
    } else {
        return `${Hour}:${Minutes}:${Seconds}`;
    }
}

export function today() {
    const date = new Date();
    const dashed_date = dash_date_formater(date, true, false);
    return dashed_date;
}


export function leadingSlash(str) {
    return str.startsWith("/") ? str : "/" + str;
}

export function trailingSlash(str) {
    return str.endsWith("/") ? str : str + "/";
}

export const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const capitalize = (inputString) => {
    let string = inputString.replaceAll(/\b([a-zA-Z])/g, (word, index) => {
        return word.toUpperCase();
    });
    return (string = string.replaceAll(/[a-zA-Z]([A-Z])/g, (word, index) => {
        return word.toLowerCase();
    }));
};

export const firstCapitalize = (inputString) => {
    return inputString.replace(/\b([a-zA-Z])/, (word, index) => {
        return word.toUpperCase();
    });
};
export function cap(str) {
    return str.replaceAll(/\b\w+\b/gi, (match) => {
        const string = match;
        return string.charAt(0).toUpperCase() + string.slice(1);
    });
}

export const shuffle = (arr) => {
    return arr.sort(() => Math.random() - 0.5);
};
export function rs(selector, obj) {
    if (typeof selector == "string") {
        selector = selector.split(".");
    }

    if (!selector?.length) {
        return obj;
    }
    try {
        return rs(selector.slice(1), obj?.[selector[0]]);
    } catch (error) {
        // console.log("Recursive select error", error)
        return undefined;
    }
}

const recursive_select = rs;

export { recursive_select };

export function random_number_generator(length) {
    let no = "";
    for (let i = 0; i < length; i++) {
        no += Math.floor(Math.random() * 10);
    }
    return no;
}

/**
 *
 * @param {String} text
 * @param {Number} max_length
 * @returns {String}
 */
export function clip(text, max_length) {
    if (!text) {
        return "";
    }
    if (text.length > max_length) {
        return `${text.slice(0, max_length - 3)}...`;
    } else {
        return text;
    }
}
function fixed(value, n = 2) {
    return Number(Number(value).toFixed(n));
}
export { fixed };

const ceil = fixed;
export { ceil };
