<template>
  <!-- eslint-disable -->
  <filtered-data-table
    :deleted="0"
    :fetch_all="true"
    :headers="headers"
    :title="title"
    :subtitle="subtitle"
    :api_url="data_api_url"
    :no_search="true"
    :no_filter="true"
  >
    <template #header_append="header_append_props">
      <v-row>
        <v-col cols="12" class="pa-0 ma-0">
          <v-chip-group v-model="filter_values"  @change="filter($event,header_append_props)" multiple>
            <v-chip small
            v-for="(name, index) in filter_list" 
            :key="index"
            >
            {{name}}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </template>
  </filtered-data-table>
</template>

<script>
/* eslint-disable */
import Api from '@/api/api'
export default {
  name: "ChipsFilteredDataTable",
  data() {
    return {
      filter_list:[],
      filter_values:[],
    };
  },
  created() {
    this.fetch_filters_values()
  },
  methods:{
    filter(e, header_append_props){
      if(e?.length == 0){
        return header_append_props.apply_filter({
          dialog:{show:true},
          filters:{}
        })
      }
      const handler = Object.keys(this.event.filters)[0]
      this.event.filters[handler].values.filter.field_values = e.map(i=>this.filter_list[i])
      this.event.dialog = {}
      this.event.dialog.show = true
      header_append_props.apply_filter(this.event)
    },
    fetch_filters_values(){
      Api.post(this.filter_api_url, {})
        .then(res => {
          this.filter_list = res.data.result.names;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  props: {
    filter_api_url:{
      type:String,
    },  
    data_api_url:{
      type:String,
    },
    title:{
      type:String, 
      default:"Filtered Table"
    },
    subtitle:{
      type:String, 
      default:"a filtered table"
    },
    headers: {
      type: Array,
      default: () => {
        return [
          { text: "Name", value: "name" },
        ];
      }
    },
    event:{
      type:Object,
      default: ()=>({
        filters:{
          accomulative_field1: { // filter backend handler name 
            values: {
              filter:{
                field:'name',
                table:'manufacturers',
                target_local_key: 'collective_manufacturers', 
                target_foreign_key: 'manufacturer_id', 
                field_values:[]
              }
            }
          },
        }
      })
    }
  }
};
</script>
