<template>
  <!-- eslint-disable -->
  <v-card flat>
    <v-card-text class="pa-8">
      <v-row>
        <h2>
          {{ values.title }}
        </h2>
        <p>
          {{ values.description }}
        </p>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-container>
            <v-row>
              {{ values.start_lable }}
            </v-row>
            <v-row>
              <v-text-field
                clearable
                v-model="values.start"
                type="number"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="6">
          <v-container>
            <v-row>
              {{ values.end_lable }}
            </v-row>
            <v-row>
              <v-text-field
                clearable
                v-model="values.end"
                type="number"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
export default {
  name: "RangeFilter",
  data() {
    return {
      default: {
        title: "Range Filter",
        description: `To filter by range you can pick a starting number, which will fetch the results larger then or equal from this starting value. or you can pick an end range which will fetch results less than or equal to this value. or you can set the two values and the filter will try to fitch the results beween the two ranges.`,
        start_lable: "start",
        end_lable: "end",
        start: "",
        end: ""
      }
    };
  },
  created() {
    this.values.title || this.$set(this.values, "title", this.default.title);
    this.values.description ||
      this.$set(this.values, "description", this.default.description);
    this.values.start_lable ||
      this.$set(this.values, "start_lable", this.default.start_lable);
    this.values.end_lable ||
      this.$set(this.values, "end_lable", this.default.end_lable);
    this.values.start || this.$set(this.values, "start", this.default.start);
    this.values.end || this.$set(this.values, "end", this.default.end);
    this.$on("clear", () => {
      this.values.start = "";
      this.values.end = "";
    });
  },
  props: {
    values: {
      type: Object,
      default: () => ({})
      // example: {
      //   title: "Range Filter",
      //   description: `To filter by range you can pick a starting number, which will fetch the results larger then or equal from this starting value. or you can pick an end range which will fetch results less than or equal to this value. or you can set the two values and the filter will try to fitch the results beween the two ranges.`,
      //   start_lable:"start",
      //   end_lable:"end",
      //   start:0,
      //   end:0
      // }
    }
  }
};
</script>

<style></style>
