import api from "@/api/api/index.js";
import rules from "@/util/rules/index.js";
import store from "@/store/index.js";

import { Geolocation } from "@capacitor/geolocation";
import { Capacitor } from "@capacitor/core"
import { Device } from "@capacitor/device"
import { today, cap, capitalize, dash_date_formater, recursive_select, padDate, clip, fixed } from "@/util/helpers.js";

import { translate } from "@/store/modules/translate.js"

/**
 *
 * @param {Vue} vue_instance
 */
export default async function (vue_instance) {
    vue_instance.prototype.$today = today;
    vue_instance.prototype.$parseInt = parseInt;
    vue_instance.prototype.$tr = translate;

    vue_instance.prototype.$back = async function () {
        if (!!this.$route.query?.from?.length) {
            await this.$reroute({
                query: {
                    to: this.$route.query.from,
                },
            });
        }
    };



    const platform = Capacitor.getPlatform()


    const deviceInfo = await Device.getInfo()
    const ios = (platform == 'ios')
    const pad = ((screen.height/screen.width) > 1.79) && ios
    vue_instance.prototype.$device_info = deviceInfo
    vue_instance.prototype.$platform = platform
    vue_instance.prototype.$ios = ios
    vue_instance.prototype.$pad = pad
    vue_instance.prototype.$ios_toolbar_class = {
        'pt-2': ios, 
        'pt-3': (pad)
    }
    vue_instance.prototype.$ios_toolbar_height = (pad)? '80': ios?'60':''


    vue_instance.prototype.pad_date = padDate;
    vue_instance.prototype.$pad_date = padDate;

    vue_instance.prototype.$clip = clip;

    vue_instance.prototype.$dash_date_formater = dash_date_formater;

    vue_instance.prototype.$notify = function (notification) {
        store.commit("user/set_notification", notification);
    };

    vue_instance.prototype.$loading = (reason, value) => {
        const loading_cover = store.getters["user/loading_cover"];
        if (!value && loading_cover?.[reason]) {
            loading_cover[reason] = false;
        } else if (value && !loading_cover?.[reason]) {
            loading_cover[reason] = true;
        }
    };
    window.gl = {}
    window.gl.gcl = Geolocation.getCurrentPosition
    window.gl.cps = Geolocation.checkPermissions
    window.gl.qps = Geolocation.requestPermissions
    vue_instance.prototype.$get_geolocation = async function get_geolocation() {
        try {

            const permissions_status = await Geolocation.checkPermissions();
            console.log('permissions')
            if (platform !== 'web' && (permissions_status.coarseLocation != 'granted' || permissions_status.location != 'granted')) {
                console.log('asking for permission')
                await Geolocation.requestPermissions()
            }

            const pos = await Geolocation.getCurrentPosition();
            if (!pos?.coords?.latitude) {
                throw { error: { msg: "Location is required" } };
            }
            return (pos);
        } catch (error) {
            console.log(error)
            throw ({
                error: {
                    msg: "Location is required",
                },
            });
        }
    };
    vue_instance.prototype.$show_location = function show_location(latitude, longitude) {
        if (arguments.length == 1) {
            const position_url = arguments[0];
            window.open(`${position_url}`, "popup", "scrollbars=yes,resizable=yes");
            return false;
        } else {
            const position_url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(`${position_url}`, "popup", "scrollbars=yes,resizable=yes");
            return false;
        }
    };
    vue_instance.prototype.$cap = cap;
    vue_instance.prototype.$api = api;
    vue_instance.prototype.$input_rules = rules;
    vue_instance.prototype.$logout = async function () {
        await store.dispatch("user/logout");
    };
    vue_instance.prototype.$sleep = async function (time) {
        return new Promise((resolve) => {
            setTimeout(() => resolve(), time);
        });
    };

    vue_instance.prototype.$recursive_select = recursive_select;

    vue_instance.prototype.$rs = recursive_select;

    vue_instance.prototype.$ceil = fixed;
    vue_instance.prototype.$fixed = fixed;
    vue_instance.prototype.$pad_id = function (id) {
        return "#" + String(id).padStart(10, "0");
    };
    vue_instance.prototype.$log = function () {
        console.log(...arguments);
    };
}
