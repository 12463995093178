<template>
<!-- eslint-disable -->
    <!-- print invoices  -->                        
    <img 
    :src="qr_img"></img>
</template>

<script>
/* eslint-disable */
import qrcode from "qrcode"
export default {
  name:"QrImage",
  data:()=>({
    qr_img:null
  }),
  watch:{
    async qr_string(val){
      console.log(val)
      this.qr_img = await qrcode.toDataURL(val)
    }
  },
  props:{
    qr_string:{
      type:String,
      default:''
    },
  },
};
</script>

<style scoped>
</style>