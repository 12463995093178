<template>
  <!-- eslint-disable -->
  <field-filter
  :values="field_filter_values"
  :options="options"
  ></field-filter>
</template>

<script>
/* eslint-disable */
export default {
  name: "UserFilter",
  data(){
    return {
        table:{
          no_search:false,
          no_filter:true,
          filters:{},
          filter_icon:'',
          api_url:'/user/fetch',
          headers: [
            { text: "ID", value: "user_id" },
            { text: "Name", value: "name" },
            { text: "User Name", value: "user_name" },
          ],
          title:"Filter By User", 
          subtitle: "Filter results by User",
          search_fields:[
            'name', 'user_name',
          ]
        },
        filter:{
          field:'user_name',
          table:'user',
          target_local_key: 'created_by_user', 
          target_foreign_key: 'user_id', 
          field_values:[]
        }
    }
  },
  props: {
    options:{
      type:Object,
      default:()=>({})
    },
    values: {
      type:Object,
      default: ()=>({
      })
    }
  },
  created(){
    if(this.values.target_local_key){
      this.filter.target_local_key = this.values.target_local_key
    }
    this.$on('clear',()=>{
      this.values.filter.field_values.splice(0)
    })
  },
  computed: {
    field_filter_values(){
      if(!this.values.table){
        this.$set(this.values,'table', this.table)
      }
      if(!this.values.filter){
        this.$set(this.values,'filter', this.filter)
      }
      return this.values
    }
  },
};
</script>

<style></style>
