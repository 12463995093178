<template>
    <v-app class="">
        <!-- loading cover -->
        <loading-cover />

        <!-- notification -->
        <v-fade-transition mode="out-in">
            <material-snackbar
                v-model="notification"
                v-bind="{
                    [notification_v]: true,
                    [notification_h]: true,
                }"
                :type="notification_color"
                timeout="5000"
            >
                {{ $tr(notification_msg) }}
            </material-snackbar>
        </v-fade-transition>

        <!-- app -->
        <v-fade-transition mode="out-in">
            <router-view />
        </v-fade-transition>
    </v-app>
</template>

<script>
/* eslint-disable */
// Styles
import "@/styles/overrides.sass";
import { sync } from "vuex-pathify";

export default {
    name: "App",
    metaInfo: {
        title: "Task Manager",
        titleTemplate: "%s | Aram Tech",
        htmlAttrs: { lang: "en" },
        meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
    },
    beforeCreate() {},
    methods: {},
    async mounted() {
        this.loading_cover["init_app"] = true;
        try {
            this.$vuetify.theme.dark = this.dark;
            await this.$store.dispatch("app/init", null, { root: true });
        } catch (error) {
            console.log(error);
        }
        window.app = this
        this.loading_cover["init_app"] = false;
    },
    watch: {
        dark(val) {
            this.$vuetify.theme.dark = val;
            this.$store.dispatch("user/update_localstorage", null, { root: true });
        },
    },
    computed: {
        ...sync("user", {
            loading_cover: "visualization@loading_cover",
            user: "user",
            notification: "notification@notification",
            notification_msg: "notification@msg",
            notification_v: "notification@v",
            notification_h: "notification@h",
            notification_color: "notification@color",
            dark: "visualization@dark",
        }),
    },
};
</script>
<style>
@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url("./fonts/Poppins-Regular.ttf") format("truetype"),
        url("./fonts/Poppins-ExtraBold.ttf") format("truetype"), url("./fonts/Poppins-Bold.ttf") format("truetype"),
        url("./fonts/Poppins-Medium.ttf") format("truetype"), url("./fonts/Poppins-SemiBold.ttf") format("truetype"),
        url("./fonts/Poppins-Thin.ttf") format("truetype"), url("./fonts/Poppins-Black.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins-Bold";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
}
</style>
