<template>
  <!-- eslint-disable -->
  <v-card flat>
    <v-card-text class="pa-8">
      <v-row>
        <h2>
          {{ values.title }}
        </h2>
      </v-row>
      <v-row>
        <p>
          {{ values.description }}
        </p>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-container>
            <v-row>
              City
            </v-row>
            <v-row>
              <v-select
                @change="update_regions"
                clearable
                :items="cities"
                v-model="values.city"
              ></v-select>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" md="6">
          <v-container>
            <v-row>
              Region
            </v-row>
            <v-row>
              <v-select
                clearable
                :items="regions"
                :disabled="!values.city || !regions.length"
                v-model="values.region"
              ></v-select>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
import Api from '@/api/api'
export default {
  name: "AddressFilter",
  data(){
    return {
      cities:[],
      regions:[],
      default: {
        title: "Address Filter",
        description: `To filter by address you need to chose a city, or chose a city and a region within that city.`,
        city:'',
        region:''
      }
    }
  },
  methods:{
    update_regions() {
      this.regions.splice(0);
      Api.post("address/region/fetch/names_by_city", {
        city_name: this.values.city
      })
        .then(response => {
          for (const region of response.data.result?.names || []) {
            this.regions.push(region);
          }
        })
        .catch(err => {
          console.log(err);
          let notification = {
            msg:
              err.response?.data?.error?.msg || "Error, please try again later",
            color: "error"
          };
          self.$store.dispatch("user/notify", notification);
        });
    },
  },
  created(){
    this.values.title || this.$set(this.values, 'title', this.default.title)
    this.values.description || this.$set(this.values, 'description', this.default.description)
    this.values.city || this.$set(this.values, 'city', this.default.city)
    this.values.region || this.$set(this.values, 'region', this.default.region)
    this.$on('clear', ()=>{
      this.values.city = ''
      this.values.region = ''
    })

    Api.post("address/city/fetch/names")
      .then(response => {
        for (const city of response.data.result?.names || []) {
          this.cities.push(city);
        }
      })
      .catch(err => {
        console.log(err);
        let notification = {
          msg:
            err.response?.data?.error?.msg || "Error, please try again later",
          color: "error"
        };
        self.$store.dispatch("user/notify", notification);
      });

  },
  props: {
    values: {
      type: Object,
      default: () => ({}),
      // example: {
      //   title: "Address Filter",
      //   description: `To filter by address you need to chose a city, or chose a city and a region within that city.`,
      //   city:'',
      //   region:''
      // }
    }
  }
};
</script>

<style></style>
