var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-8"},[_c('v-row',[_c('h2',[_vm._v(" Filter By Date and Time ")]),_c('p',[_vm._v(" to filter by date and time you can pick a start date and time, which will fetch the results starting from this point. or you can pick an end date and time which will fetch results till this point. or you can set the two values and the filter will try to fitch the results beween the two point of time. ")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',[_c('v-row',[_vm._v(" Start Date And Time ")]),_c('v-row',[_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"royal"}},[_vm._v(" mdi-calendar-clock ")])],1),_c('div',[_c('datetime',{style:(`background-color:${
                    _vm.$vuetify.theme.dark
                      ? _vm.$vuetify.theme.themes.light.greyl1
                      : _vm.$vuetify.theme.themes.light.greyl3
                  };border: 1px solid ${
                    _vm.$vuetify.theme.themes.light.royal
                  };border-left: 5px solid ${
                    _vm.$vuetify.theme.themes.light.royal
                  }`),attrs:{"persistant":"","type":"datetime"},model:{value:(_vm.values.start),callback:function ($$v) {_vm.$set(_vm.values, "start", $$v)},expression:"values.start"}})],1),_c('div',[_c('v-btn',{attrs:{"icon":"","text":"","x-small":""},on:{"click":function($event){_vm.values.start = ''}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"error"}},[_vm._v(" mdi-close ")])],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',[_c('v-row',[_vm._v(" End Date And Time ")]),_c('v-row',[_c('div',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"royal"}},[_vm._v(" mdi-calendar-clock ")])],1),_c('div',[_c('datetime',{style:(`background-color:${
                    _vm.$vuetify.theme.dark
                      ? _vm.$vuetify.theme.themes.light.greyl1
                      : _vm.$vuetify.theme.themes.light.greyl3
                  };border: 1px solid ${
                    _vm.$vuetify.theme.themes.light.royal
                  };border-left: 5px solid ${
                    _vm.$vuetify.theme.themes.light.royal
                  }`),attrs:{"persistant":"","type":"datetime"},model:{value:(_vm.values.end),callback:function ($$v) {_vm.$set(_vm.values, "end", $$v)},expression:"values.end"}})],1),_c('div',[_c('v-btn',{attrs:{"icon":"","text":"","x-small":""},on:{"click":function($event){_vm.values.end = ''}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"error"}},[_vm._v(" mdi-close ")])],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }