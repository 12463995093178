/* eslint-disable */

window.sleep = async function(time) {
  return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
  });
};

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

Vue.config.productionTip = false

import vue_manipulator from "./util/manipulator.js";
Vue.use(vue_manipulator);


import VuePullRefresh from "./plugins/loadmore/index.js";
Vue.use(VuePullRefresh)

import VueModels from "vue2-multiple-vmodels";
Vue.use(VueModels);

sync(store, router)

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount('#app')
