/* eslint-disable */
// Pathify
import { make } from "vuex-pathify";

// router
import router from "../../router/index";

// Data
const state = {
    // admin app layout
    drawer: null,
    drawerImage: true,
    mini: false,

    // all roles
    items: [
        // admin
        {
            title: "Home",
            icon: "mdi-home",
            to: "/app/admin/home",
            roles: {
                allow: ["admin", "sr_admin", "mr_admin"],
            },
        },
        {
            title: "Profile",
            icon: "mdi-account",
            to: "/app/admin/profile",
            roles: {
                allow: ["admin", "sr_admin", "mr_admin"],
            },
        },

        // mr
        {
            title: "Home",
            icon: "mdi-home",
            to: "/app/employee/home",
            roles: {
                allow: ["mr"],
            },
        },
        {
            icon: "mdi-plus-circle-outline",
            icon_size: 55,
            fab: true,
            to: "/app/employee/add_bundle",
            roles: {
                allow: ["mr"],
            },
        },
        {
            title: "Profile",
            icon: "mdi-account",
            to: "/app/employee/profile",
            roles: {
                allow: ["mr"],
            },
        },

        // sr
        {
            title: "Home",
            icon: "mdi-home",
            to: "/app/sr/home",
            roles: {
                allow: ["sr"],
            },
        },
        {
            title: "Profile",
            icon: "mdi-account",
            to: "/app/sr/profile",
            roles: {
                allow: ["sr"],
            },
        },
    ],
};

const mutations = make.mutations(state);

/**
 * @type {import("vuex").ActionTree<any>}
 */
const actions = {
    ...make.actions(state),
    init: async ({ dispatch }) => {
        await dispatch("translate/init", null, {
            root: true,
        });
    },
};

/**
 * @type {import("vuex").GetterTree<any>}
 */
const getters = {
    //admin
    appbarPageButtonns(state) {
        return state.appbarPageButtonnsCollections[router.currentRoute.name];
    },

    // employee
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
