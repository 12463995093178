<template>
  <!-- eslint-disable -->

  <v-card :height="height">
    <v-card-text class="fill-height">
      <v-container class="fill-height">
        <canvas v-show="ch1.data" :height="height" :id="can_id"></canvas>

        <v-row v-show="!ch1.data" justify="center" align="center">
          <div>No Data Available</div>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable */
import Chart from "chart.js/auto";
import Api from "@/api/api";
export default {
  name: "GChart",
  computed: {
    can_id() {
      return this.label.replace(" ", "");
    }
  },
  data: () => ({
    ch1_chart: null,
    ch1: {
      type: "",
      data: null,
      options: {}
    }
  }),
  props: {
    url: {
      type: String,
      required: true
    },
    height: {
      type: [Number,String],
      default: null
    },
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    background_color: {
      type: String,
      default: "random"
    },
    shuffle: {
      type: Boolean,
      default: false
    },
    full_label: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    console.log("g-chart baby", this.url, this.shuffle, this.type);
    this.ch1.type = this.type;
    const ctx = document.getElementById(this.can_id).getContext("2d");
    const colors = this.$vuetify.theme.themes.light;
    for (const key in colors) {
      if (key.startsWith("grey")) {
        delete colors[key];
      }
    }
    const colors_arr = Object.values(colors);
    Api.post(this.url)
      .then(res => {
        if (!res.data.result.data.length) {
          return;
        }

        // shuffeling
        if (this.shuffle) {
          res.data.result.data = res.data.result.data.sort(
            () => Math.random() - 0.5
          );
        }

        // getting data (x,y)
        this.ch1.data = {};
        if(this.full_label){
          this.ch1.data.labels =  res.data.result.data.map(el=>el.x)
        }else{
          this.ch1.data.labels = res.data.result.data.map(el => {
            if (typeof el.x == "string") {
              return el.x.split(" ").slice(-1);
            } else {
              return el.x;
            }
          });
        }
        this.ch1.data.datasets = [{}];
        this.ch1.data.datasets[0].data = res.data.result.data.map(el => el.y);
        this.ch1.data.datasets[0].label = this.label;
        this.ch1.data.datasets[0].borderWidth = 1;
        // coloring
        if (this.background_color[0] == "#") {
          if (this.background_color.length == 7) {
            this.ch1.data.datasets[0].backgroundColor = `${this.background_color}99`;
            this.ch1.data.datasets[0].backgroundColor = `${this.background_color}99`;
          } else {
            this.ch1.data.datasets[0].backgroundColor = this.background_color;
            this.ch1.data.datasets[0].borderColor = this.background_color;
          }
        } else if (this.background_color.startsWith("rgb")) {
          this.ch1.data.datasets[0].backgroundColor = this.background_color;
        } else if (this.background_color == "random") {
          const background_colors = [];
          const border_colors = [];
          for (let i = 0; i < this.ch1.data.labels.length; i++) {
            const random_color =
              colors_arr[Math.floor(Math.random() * colors_arr.length)];
            background_colors.push(`${random_color}99`);
            border_colors.push(`${random_color}ff`);
          }
          this.ch1.data.datasets[0].backgroundColor = background_colors;
          this.ch1.data.datasets[0].borderColor = border_colors;
        } else {
          this.ch1.data.datasets[0].backgroundColor = `${
            colors[this.background_color]
          }99`;
          this.ch1.data.datasets[0].borderColor = `${
            colors[this.background_color]
          }ff`;
        }
        console.log(this.ch1);
        this.ch1_chart = new Chart(ctx, {...this.ch1, options:{
          maintainAspectRatio: false,
          plugins: {
              title: {
                  display: true,
                  text: this.label
              }
          }
        }});
      })
      .catch(err => {
        console.log(err);
        const self = this;
        let notification = {
          msg:
            err.response?.data?.error?.msg || "Error, please try again later",
          color: "error"
        };
        self.$store.dispatch("user/notify", notification);
      });
  }
};
</script>

<style></style>
