/* eslint-disable */
// Utilities
import { make } from "vuex-pathify";

// Globals
import { IN_BROWSER } from "@/util/globals";

// router
import router from "../../router/index";
const state = {
    user: null,
    default_routes_for_roles: {
        admin: "/app/admin/home",
        sr_admin: "/app/admin/home",
        mr_admin: "/app/admin/home",
        sr: "/app/sr/home",
        mr: "/app/employee/home",
    },
    visualization: {
        loading_cover: {},
        dark: false,
        // admin
        drawer: {
            image: 0,
            gradient: 0,
            mini: false,
        },
        // admin
        gradients: [
            "",
            "rgb(6,76,134), rgb(6,76,134)",
            "rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)",
            "rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)",
            "rgba(244, 67, 54, .8), rgba(244, 67, 54, .8)",
        ],
        // admin
        images: [],
        rtl: false,
    },
    notification: {
        notification: false,
        msg: "",
        color: "warning",
        v: "top",
        h: "center",
    },
    auth: {
        token: "",
    },
};

const saved_status = JSON.parse(localStorage.getItem("aramtech@user"));

if (saved_status) {
    state.user = saved_status.user;
    state.auth.token = saved_status?.auth?.token;
}

const mutations = {
    // default mudations
    ...make.mutations(state),
    set_theme(state, value) {
        state.visualization.dark = value;
    },
    set_user_data(state, value) {
        state.auth.token = value.token || null;
        state.user = value.user || {};
    },
    set_notification(state, notification) {
        state.notification.notification = true;
        state.notification.msg = notification?.msg || "An Error Occured, Please Try Again Later";
        state.notification.color = notification?.color || "error";
        state.notification.h = "center";
        console.log(router.currentRoute);
        if (router.currentRoute.name.indexOf("Dashboard") == -1) {
            state.notification.v = "bottom";
        } else {
            state.notification.v = "top";
        }
    },
};

/**
 * @type {import("vuex").ActionTree<any>}
 */
const actions = {
    // auth
    async set_user_data(context, result) {
        if (result.token) {
            context.commit("set_user_data", result);
        } else {
            context.commit("set_user_data", {});
        }
        context.dispatch("update_localstorage");
    },

    async logout(context) {
        try {
            await context.dispatch("set_user_data", {});
            await context.dispatch("translate/change_language", "en", { root: true });
            if (router.currentRoute.fullPath != "/") {
                await router.push("/");
            }
        } catch (error) {
            context.commit("set_notification", {
                msg: "Logout Failed, please try again later",
            });
        }
    },
    // notification
    notify(context, notification) {
        context.commit("set_notification", notification);
    },

    // local storage
    fetch_localstorage: async ({ commit }) => {
        const local = (localStorage.getItem("aramtech@user")) || "{}";
        const user = await JSON.parse(local);

        for (const key in user) {
            commit(key, user[key]);
        }

        if (user.visualization.dark === undefined) {
            commit("set_theme", window.matchMedia("(prefers-color-scheme: dark)"));
        }
        return new Promise((resolve) => resolve());
    },
    update_localstorage: ({ state }) => {
        if (!IN_BROWSER) return;
        localStorage.setItem("aramtech@user", JSON.stringify(state));
    },
};

const getters = {
    // auth
    auth: (state) => {
        return !!state.auth.token && state.user;
    },
    loading_cover: (state) => {
        return state.visualization.loading_cover;
    },
    default_route_for_user_role: (state) => {
        if (!!state.auth.token) {
            if (state.default_routes_for_roles[state.user.role]) {
                return state.default_routes_for_roles[state.user.role];
            } else {
                return `/app/${state.user.role}/profile`;
            }
        } else {
            return "/";
        }
    },
    role: (state) => {
        return state.user?.role;
    },
    token: (state) => {
        return state.auth.token;
    },

    // admin
    // visual getters
    dark: (state, getters) => {
        return state.visualization.dark || getters.gradient.indexOf("255, 255, 255") === -1;
    },
    // admin
    dark_gradiant: (state) => {
        console.log(state.visualization.drawer.gradient);
        switch (state.visualization.drawer.gradient) {
            case 1:
                return true;
            case 2:
                return true;
            case 4:
                return true;
            default:
                return false;
        }
    },
    // admin
    gradient: (state) => {
        console.log("getiting gradient");
        return state.visualization.gradients[state.visualization.drawer.gradient];
    },
    // admin
    image: (state) => {
        return state.visualization.drawer.image === ""
            ? ""
            : state.visualization.images[state.visualization.drawer.image];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
