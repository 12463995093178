import {capitalize} from '../../util/helpers.js'


const is_empty = (value)=>{
    return (value===''||value===null||value===undefined)
}


export default {
    in:(field, source_list)=>{
        field = capitalize(field);
        return (value)=>{
            if(value === null || value=== undefined || value === ''){return true}
            return !!source_list?.includes(value) || `${field} must exist`
        }
    },
    boolean(field){
        field = capitalize(field);
        return (value) => {
            if (value === true || value === false || value === 0 || value === 1) {
                return true;
            }
            return `${field} must Valid Boolean`;
        };
    }, 
    required:(field)=>{
        field = capitalize(field)
        return (value)=>{
            const message = `${field} is required`
            if(typeof value == 'object' && Array.isArray(value)){
                return !!value.length || message
            }else{
                return !is_empty(value) || message 
            }
        }
    }, 
    title:(field, max_length=250, min_length)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            if(typeof value != 'string'){
                return `${field} must be a string`
            }
            if(value.length > max_length){
                return `${field} must be less then ${max_length} in length`
            }
            if (min_length && value.length < min_length) {
                return `${field} must be greater then ${min_length} in length`;
            }
            return true
        }
    }, 
    name:(field)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            try {
                return !!value.trim().match(/^(?:\p{Letter}{3,20})(?:\p{Z}{1,2}\p{Letter}{1,20}){1,3}$/ui) || `${field} is not valid full name`      
            } catch {
                return `${field} is not valid full name`
            }
        }
    }, 
    email:(field)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            try {
                return !!value.trim().match(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/) || `${field} is not valid email`      
            } catch {
                return `${field} is not valid email`
            }
        }
    }, 
    phone:(field)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            try {
                return !!value.trim().match(/^((\+|00)\s?\d{1,3}\s?)?(\(?\d{2,3}\)?)(\-|\s)?(\d{3}(\-|\s)?\d{4})$/) || `${field} is not valid phone`      
            } catch {
                return `${field} is not valid phone`
            }
        }
    }, 
    password:(field)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            try {
                return !!value.trim().match(/^[a-zA-z0-9_\-\!\@\#\$\%\^\&\*\(\)\+\<\>\.\?\,\;\|]{4,20}$/) || `${field} is not valid password`      
            } catch {
                return `${field} is not valid password`
            }
        }
    }, 
    username:(field)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            try {``
                return !!value.trim().match(/^[a-zA-z_][a-zA-z0-9_\-]{3,}$/) || `${field} is not valid username`      
            } catch {
                return `${field} is not valid username`
            }
        }
    }, 
    number:(field, max, min)=>{
        field = capitalize(field)
        return (value)=>{
            if(is_empty(value)){return true}
            const msg = `${field} is not a valid number`
            try {
                const number_value = parseFloat(value)
                if(Number.isNaN(number_value)){
                    return msg
                }
            
                if(typeof max === 'number' && number_value > max){
                    return `${field} must be less then ${max}`
                }

                if(typeof min === 'number' && number_value < min){
                    return `${field} must be greater then ${min}`
                }
                return  true
            } catch (error) {
                return msg
            }
        }
    }
}