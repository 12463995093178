/* eslint-disable */
// Imports
import Vue from "vue";
import Router from "vue-router";
import { layout, route } from "@/util/routes";
import store from "../store/index";

Vue.use(Router);

const router = new Router({
    // mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior: (to, from, savedPosition) => {
        if (to.hash) return { selector: to.hash };
        if (savedPosition) return savedPosition;

        return { x: 0, y: 0 };
    },
    routes: [
        // page
        layout(
            "Page",
            [
                // Junk
                route("Error", null, "error"),
                route("Support", null, "support"),

                // Auth
                route("Login", null, "", "Auth/Login.vue"),
                route("test", null, "/test", "Test/test.vue"),
            ],
            "",
            { requiresVisitor: true }
        ),

        // mobile
        layout(
            "Mobile",
            [
                // admin
                route("AdminProfile", null, "admin/profile", "Admin/Profile/index.vue", {
                    authorized_roles: {
                        allow: ["admin", "sr_admin", "mr_admin"],
                    },
                }),
                route("AdminHome", null, "admin/home", "Admin/Home/index.vue", {
                    authorized_roles: {
                        allow: ["admin", "sr_admin", "mr_admin"],
                    },
                }),

                // mr
                route("EmployeeProfile", null, "employee/profile", "Employee/Profile/index.vue", {
                    authorized_roles: {
                        allow: ["mr"],
                    },
                }),
                route("EmployeeHome", null, "employee/home", "Employee/Home/index.vue", {
                    authorized_roles: {
                        allow: ["mr"],
                    },
                }),
                route("EmployeeAddBundle", null, "employee/add_bundle", "Employee/Home/AddBundle.vue", {
                    authorized_roles: {
                        allow: ["mr"],
                    },
                }),

                // sr
                route("SrHome", null, "sr/home", "Sr/Index.vue", {
                    authorized_roles: {
                        allow: ["sr"],
                    },
                }),
                route("EmployeeProfile", null, "sr/profile", "Sr/Profile/Index.vue", {
                    authorized_roles: {
                        allow: ["sr"],
                    },
                }),

                route("SalesRepTasksLog", null, "sr/tasks_log", "Sr/History/Index.vue", {
                    authorized_roles: {
                        allow: ["sr"],
                    },
                }),
            ],
            "/app",
            {
                RequiresAuth: true,
                authorized_roles: {
                    allow: ["admin", "sr_admin", "mr_admin", "sr", "mr"],
                },
            }
        ),
    ],
});


router.beforeEach((to, from, next) => {
    console.log("Routing ......", "\n", to.fullPath, "\n", from.fullPath);
    let auth = store.getters["user/auth"];
    if (to.matched.some(record => record.meta.RequiresAuth)) {
        if (!auth) {
            next({
                name: "Login",
                query: { redirect: to.fullPath }
            });
        } else {
            if (
                to.matched.every(record => {
                    if (record.meta.authorized_roles) {
                        if (record.meta.authorized_roles.allow) {
                            return record.meta.authorized_roles.allow.includes(
                                store.getters["user/role"]
                            );
                        } else if (record.meta.authorized_roles.reject) {
                            return !record.meta.authorized_roles.reject.includes(
                                store.getters["user/role"]
                            );
                        } else {
                            return true;
                        }
                    } else {
                        return true;
                    }
                })
            ) {
                next();
            } else {
                next({ path: store.getters["user/default_route_for_user_role"] });
            }
        }
    } else if (to.matched.some(record => record.meta.requiresVisitor)) {
        console.log("requires visitor, auth: ", auth);
        if (auth) {
            next({ path: store.getters["user/default_route_for_user_role"] });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
