import api from "@/api/api/index.js";
import vuetify from "@/plugins/vuetify.js";



import { Capacitor } from "@capacitor/core"

const localst_storage_key = "language@ag-group@aramtech";

/**
 * @returns {Array<LanguagePackIdentifier>}
 */
const get_available_languages = async (now = false) => {
    return (await api.post("/translate/fetch/available_language_packs", null, {
        sinceMins: 4320,
        now: now,
    })).data.languages_packs;
};

/**
 * @param {string} language_key
 * @return {Promise<LanguagePack>}
 */
const get_language_pack = async (language_key, now = false) => {
    return (await api.post("/translate/fetch/language_pack", { language_key: language_key }, {
        sinceMins: 4320,
        now: now,
    })).data.language_pack;
};
/**
 * @typedef {Object} LanguagePackIdentifier
 * @property {String} text
 * @property {String} value
 * @property {Boolean} default
 */

/**
 * @typedef {Object} LanguagePack
 * @property {String} text
 * @property {String} value
 * @property {Boolean} rtl
 * @property {Boolean} default
 * @property {object.<string,string>} map
 */

/**
 * @typedef {Object} LanguageStore
 * @property {LanguagePack} current_language
 * @property {LanguagePack} default_language
 */

/**
 * @type {LanguageStore}
 */
const state = {
    current_language: {
        text: "English",
        value: "en",
        rtl: false,
        default: true,
        map: {},
    },
    default_language: {
        text: "English",
        value: "en",
        rtl: false,
        default: true,
        map: {},
    },
};

/**
 * @type {import("vuex").MutationTree<LanguageStore>}
 */
const mutations = {};

/**
 * @type {import("vuex").ActionTree<LanguageStore>}
 */
const actions = {
    /**
     * @param {import("vuex").ActionContext<LanguageStore>}
     * @param {string} language_key
     * @return {Promise<void>}
     */
    async change_language(context, language_key) {
        const now = !!language_key?.now
        language_key = language_key?.key || language_key
        context.state.current_language = await get_language_pack(language_key, now);
        vuetify.framework.rtl = context.state.current_language.rtl;
        localStorage.setItem(localst_storage_key, JSON.stringify(context.state.current_language))
    },
    async init({ state }) {
        try {

            console.log("initializing language pack")
            /**
             * @type {LanguagePack}
             */
            const saved_language_configuration = JSON.parse(
                window.localStorage.getItem(localst_storage_key) || "{}"
            );
            if (!saved_language_configuration.value) {
                throw null
            } else {
                state.current_language = await get_language_pack(saved_language_configuration.value);
            }
        } catch (error) {
            state.current_language = JSON.parse(JSON.stringify(state.default_language));
        }
        vuetify.framework.rtl = state.current_language.rtl;
        localStorage.setItem(localst_storage_key, JSON.stringify(state.current_language));
    },
};

/**
 * @type {import("vuex").GetterTree<LanguageStore>}
 */
const getters = {
    current_language(state, getters) {
        return state.current_language;
    },
};

/**
 *
 * @param {String} text
 * @returns {String}
 */
const translate = (text) => {
    if (state.current_language.default) {
        return text;
    } else {
        const translation = state.current_language.map[text]
        if (translation) {
            return translation
        } else {

            text && console.log("translation not found: ", text)
            return text;
        }
    }
};

/**
 * @type {import("vuex").Module<any>}
 */
const module = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
export default module;
export { get_available_languages };
export { get_language_pack };
export { translate };
