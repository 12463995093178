<template>
  <!-- eslint-disable -->
  <v-container fluid>
    <!-- Item clear Confirmation Dialog -->
    <div class="">
      <v-dialog
        v-model="item_clear_dialog.show"
        persistent
        :max-width="$vuetify.breakpoint.mdAndUp ? '400px' : ''"
      >
        <!-- Tabs -->
        <v-card v-if="item_clear_dialog.item">
          <v-card-text class="pa-4">
            <p class="text-h3">Clear Missed Sheets</p>
            <p class="">
              clear missed sheets for 
              {{ item_clear_dialog.item.name }}?
            </p>
          </v-card-text>
          <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn
              @click="item_clear_dialog.show = false"
              :disabled="item_clear_dialog.btns.loading"
              :loadgin="item_clear_dialog.btns.loading"
              text
              color="royal"
              >No</v-btn
            >
            <v-btn
              @click="claer_doctor_missed_sheets"
              text
              color="royal"
              :disabled="item_clear_dialog.btns.loading"
              :loadgin="item_clear_dialog.btns.loading"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Item table -->
    <filtered-data-table
      :cleard="0"
      :search_fields="[]"
      :table_actions="table_actions"
      :headers="headers"
      api_url="/dashboard/fetch/doctor_unused_sheets"
      :fetch_all="true"
      title="Doctors Missed Sheets"
      subtitle="Doctors missed sheets"
      :no_search="true"
      :no_filter="true"
      filter_icon="mdi-doctor"
      @clear="open_clear_dialog($event)"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import Api from "@/api/api";
export default {
  name:"DoctorMissedSheetsTable",
  data: () => ({
    // clear Doctro Dialog Data
    item_clear_dialog: {
      show: false,
      item: null,
      refresh: null,
      btns: {
        loading: false
      }
    },

    // table
    headers: [
      { text: "Doctor Name", value: "name" },
      { text: "Speciality", value: "speciality" },
      { text: "Miss Count", value: "miss_count", sortable:false },
    ],
    table_actions: {
      name: "Table Actions",
      actions: [
        {
          event: "clear",
          icon: "mdi-close"
        }
      ]
    },
  }),
  methods: {
    async open_clear_dialog(event) {
      await this.$set(this.item_clear_dialog, "item", event.item);
      await this.$set(this.item_clear_dialog, "refresh", event.refresh);
      this.item_clear_dialog.show = true;
    },
    claer_doctor_missed_sheets() {
      this.item_clear_dialog.btns.loading = true;
      Api.post("/doctor/update/clear_missed_sheets", {
        name: this.item_clear_dialog.item.name
      })
        .then(res => {
          this.item_clear_dialog.btns.loading = false;
          this.item_clear_dialog.show = false;
          this.item_clear_dialog.refresh();
        })
        .catch(err => {
          const self = this
          let notification = {
            msg:
              err.response?.data?.error?.msg || "Error, please try again later",
            color: "error"
          };
          self.$store.dispatch("user/notify", notification);
          this.item_clear_dialog.btns.loading = false;
          this.item_clear_dialog.show = false;
        });
    }
  }
};
</script>

<style></style>
